
import { defineComponent, PropType } from 'vue-demi';
import { mapGetters } from 'vuex';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { getCurrencyTwo, numberSpaces, getCorrectInstrumentsCurrency, FV, currentTerm, getCurrency } from '../../../../../../table/modules/container/calculation';

export default defineComponent({
  name: 'PortfolioExpert',
  props: {
    portfolioId: {
      type: String,
      required: true,
    },
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true
    },
    course: {
      type: String,
      required: true
    },
    target: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showButton: false,
      showActual: true,
    }
  },
  computed: {
    ...mapGetters(['uncombine']),
    targetType() {
      return this.target.type.name;
    },
    currency() {
      return this.target.main.data.currency_sign;
    },
    currency_id() {
      return this.target.main.data.currency_id;
    },
    total() {
      if (this.portfolioId === 'existing') {
        let total = getCorrectInstrumentsCurrency(this.portfolio.sections[1].modules.concat(this.portfolio.sections[2].modules).map(m => m.data).filter(i => i.name && i.price), this.currency_id, this.course);
        return Number(total.replace(/ /g, ''));
      }
      if (this.portfolioId === 'expert') {
        const { inflation, profitability } = this.target.main.data;
        const term = this.target.type.sections[1].modules[0].data;
        const correctInflation = inflation / 100;
        const correctProfitability = (profitability / 100 ) || 0.10;
        const amount = this.target.type.sections[0].modules[0].data.amount;
        const current_term = currentTerm(term);

        let fv = Number(FV(
          amount,
          correctInflation,
          current_term
        ));
        const capital = (fv * 12) / correctProfitability;
        if (this.target.type.id === 2) fv = capital;

        if (this.course === 'one' && this.uncombine) {
          fv = this.target.type.sections[3].modules[0].data.fv.replace(` ${this.currency}`, '');
        }
        return fv;
      }
      return this.target.type.sections[2].modules[0].data.amount;
    },
    term() {
      const current = this.target.type.sections[1].modules[0].data;
      return `${current.term} ${current.duration}`;
    },
    risk() {
      return `${this.target.type.sections[5].modules[0].data.portfolio}`;
    },
    tactic() {
      const tactic = this.total * (this.portfolio.sections[0].modules[0].data.tactic / 100);
      return Math.ceil10(tactic, -2);
    },
    core() {
      const core = this.total * (this.portfolio.sections[0].modules[0].data.core / 100);
      return Math.ceil10(core, -2);
    },
    remainder() {
      let amount = getCorrectInstrumentsCurrency(this.portfolio.sections[1].modules.map(m => m.data).filter(i => i.name && i.price), this.currency_id, this.course);
      if (this.portfolio.sections[1].modules.map(m => m.data).filter(i => i.name && i.price).length === 0) amount = '0';
      return this.core - Number(amount.replace(/ /g, ''));
    }
  },
  methods: {
    numberSpaces,
    input(type: string) {
      let correct = Number(this.portfolio.sections[0].modules[0].data[type]);
      if (correct > 100) {
        this.portfolio.sections[0].modules[0].data[type] = 100;
        this.portfolio.sections[0].modules[0].data[type === 'core' ? 'tactic' : 'core'] = 0;
      }
      this.portfolio.sections[0].modules[0].data[type === 'core' ? 'tactic' : 'core'] = 100 - this.portfolio.sections[0].modules[0].data[type];
      this.showButton = true;
    },
    percents() {
      const total = this.core;
      const currency = this.target.main.data.currency_id;
      this.portfolio.sections[1].modules.forEach(m => {
        m.data.number_papers = Math.floor((total * (+m.data.percent / 100)) / getCurrencyTwo(m.data, currency, this.course));
      });
      this.showButton = false;
    },
    async actualPrices() {
      const instruments = this.portfolio.sections[1].modules
        .filter(m => m.data.name && m.data.price)
        .map(m => ({ name: m.data.name, currency_two_id: m.data.currency_two_id, lot: m.data.lot, number_papers: m.data.number_papers }));
      
      const result = await this.API.instrument.actualPrices({ instruments });
      for (const i of result.data) {
        const c = this.portfolio.sections[1].modules.find(m => m.data.name.trim().toLowerCase() === i.name.trim().toLowerCase());
        if (c) {
          c.data.price = i.price || c.data.price;
          c.data.formula = i.formula || c.data.formula;
          const amount = getCurrency(c.data, this.target.main.data.currency_id, this.course);
          c.data.percent = Math.ceil10(amount / this.core * 100, -2);
        }
      }
      this.showActual = false;
    }
  }
})
